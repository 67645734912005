import React, { useState, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import {
	Typography,
	IconButton,
	ClickAwayListener,
	Checkbox,
	Dialog,
	TextField,
	Input,
	Button,
	Icon,
	Grid,
	Radio,
	RadioGroup,
	Paper,
	Card,
	CardContent,
	CardHeader,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import Tooltip from '@material-ui/core/Tooltip'
import { DateRangePicker } from 'react-date-range'
import { fromTodayStaticRanges, fromTodayInputRange } from '../../../../utils/defaultDateRanges'
import _ from 'lodash'
// import Daterange from '../../../../components/DateRangeInput'
import InputField from '../../../../components/TextField'
import AddButton from 'images/PeopleAdd.PNG'
import { format } from 'date-fns'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Form, Field as FormikField } from 'formik'
import Progressbar from './Progressbar'
import PeopleCard from './PeopleCard'
import AddPeoplexDialog from './AddPeoplexDialog'
import { useHistory } from 'react-router-dom'
import cpuImg from '../../../../images/cpu.png'
import gpuImg from '../../../../images/gpu.png'
import memoryImg from '../../../../images/memory.png'
import { showLoader, hideLoader, showErrorMessage } from '../../../../services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import ProjectCreationApi from '../../../../services/projects_creation/api'
import Axios from 'axios'
import moment from 'moment'
import { getErrMsg } from 'utils'

const useStyles = makeStyles((theme) => ({
	root: {
		// width: 300 + theme.spacing(3) * 2,
		marginBottom: '10px',
	},
	margin: {
		height: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	calender: {
		textAlign: 'center',
		padding: '10px',
		'& .MuiFormControl-root': {
			[theme.breakpoints.up(600)]: {
				width: '275px',
			},
			width: '-webkit-fill-available',
		},
	},
	calenderTimeLine: {
		textAlign: 'center',
		padding: '10px 4px',
		'& .MuiFormControl-root': {
			[theme.breakpoints.up(600)]: {
				width: '275px',
			},
			width: '-webkit-fill-available',
		},
	},
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	peopleEditMode: {
		height: '100%',
		// '& .MuiCardContent-root': {
		// 	padding: '16px 16px 10px 16px !important',
		// },
	},
	peopleList: {
		display: 'none',
	},
	paper: {
		// width: '100%',
		margin: '16px auto',
		// background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		// textDecoration: 'underline',
	},
	addButton: {
		width: 'inherit',
		// [theme.breakpoints.down('sm')]: {
		// 	width: 'inherit',
		// },
	},
	calendarWrapper: {
		// position: 'absolute',
		// top: '100%',
		// right: '0%',
		// zIndex: 5,
		padding: theme.spacing(4),
		background: theme.palette.almostBlack[100],
		boxShadow: `0px 0px 5px 0px ${theme.palette.almostBlack[400]}`,
		// borderRadius: '10px',
		'& .rdrDefinedRangesWrapper': {
			borderRadius: '4px 0px 0px 4px',
			'& .rdrStaticRanges': {
				borderRadius: '4px 0px 0px 0px',
				'& .rdrStaticRange:first-child, .rdrStaticRange:first-child:hover': {
					borderRadius: '4px 0px 0px 0px',
					'& .rdrStaticRangeLabel': {
						borderRadius: '4px 0px 0px 0px',
					},
				},
			},
		},
		'& .rdrCalendarWrapper': {
			borderRadius: '0px 4px 4px 0px',
			'& .rdrDateDisplayWrapper': {
				borderRadius: '0px 4px 0px 0px',
			},
		},
	},
}))

function ValueLabelComponent(props) {
	const { children, open, value } = props

	return (
		<Tooltip open={open} enterTouchDelay={0} placement='top' title={value} arrow>
			{children}
		</Tooltip>
	)
}

ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
}

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const marks = [
	{
		value: 0,
		label: '$ 0',
	},
	// {
	// 	value: 20,
	// },
	// {
	// 	value: 37,
	// },
	{
		value: 100000000,
		label: '$ 100M',
	},
]

const Field = (props) => {
	return (
		<FormikField type={props?.type} name={props?.name}>
			{props?.render}
		</FormikField>
	)
}

const IOSSlider = withStyles({
	root: {
		color: '#3880ff',
		height: 2,
		padding: '15px 0',
	},
	thumb: {
		height: 28,
		width: 28,
		backgroundColor: '#fff',
		boxShadow: iOSBoxShadow,
		marginTop: -14,
		marginLeft: -14,
		'&:focus, &:hover, &$active': {
			boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: iOSBoxShadow,
			},
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 12px)',
		top: -22,
		'& *': {
			background: 'transparent',
			color: '#000',
		},
	},
	track: {
		height: 2,
	},
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: '#bfbfbf',
	},
	mark: {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		marginTop: -3,
	},
	markActive: {
		opacity: 1,
		backgroundColor: 'currentColor',
	},
})(Slider)

const PrettoSlider = withStyles({
	root: {
		color: '#52af77',
		height: 8,
	},
	thumb: {
		height: 18,
		width: 18,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		marginTop: -5,
		marginLeft: -12,
		// '&:focus, &:hover, &$active': {
		// 	boxShadow: 'inherit',
		// },
		'&:focus, &:hover, &$active': {
			boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: iOSBoxShadow,
			},
		},
	},
	active: {},
	valueLabel: {
		left: 'calc(-95% + 4px)',
		top: -22,
		'& *': {
			background: 'transparent',
			color: '#000',
		},
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider)

const AirbnbSlider = withStyles({
	root: {
		color: '#3a8589',
		height: 3,
		padding: '13px 0',
	},
	thumb: {
		height: 27,
		width: 27,
		backgroundColor: '#fff',
		border: '1px solid currentColor',
		marginTop: -12,
		marginLeft: -13,
		boxShadow: '#ebebeb 0 2px 2px',
		'&:focus, &:hover, &$active': {
			boxShadow: '#ccc 0 2px 3px 1px',
		},
		'& .bar': {
			// display: inline-block !important;
			height: 9,
			width: 1,
			backgroundColor: 'currentColor',
			marginLeft: 1,
			marginRight: 1,
		},
	},
	active: {},
	track: {
		height: 3,
	},
	rail: {
		color: '#d8d8d8',
		opacity: 1,
		height: 3,
	},
	textField: {
		'& .MuiFormControlLabel-label.Mui-disabled': {
			color: '#171725',
		},
	},
	people: {
		'& .MuiGrid-item': {
			padding: '10px !important',
		},
	},
})(Slider)

function AirbnbThumbComponent(props) {
	return (
		<span {...props}>
			<span className='bar' />
			<span className='bar' />
			<span className='bar' />
		</span>
	)
}

export default function CustomizedSlider(props) {
	const {
		initialValues,
		values,
		setInitVals,
		initVals,
		setApplyClicked,
		errors,
		touched,
		handleBlur,
		handleChange,
		disabled,
		isViewMode,
		isCreateMode,
		isEditMode,
	} = props
	const classes = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const cancelExecutor = useRef()
	const dispatch = useDispatch()

	const [value, setValue] = React.useState(values.project_cost)

	const [dates, setDates] = useState(values.timeline)
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)

	const [openPeoplex, setOpenPeoplex] = useState(false)
	const [projectMembers, setProjectMembers] = useState(values.project_members)

	const peopleRecommendAvl = useMemo(() => {
		const labels = !_.isEqual(initialValues?.labels, values?.labels)
		const project_cost = !_.isEqual(_.toString(initialValues?.project_cost), _.toString(values?.project_cost))
		const wersel_x = !_.isEqual(initialValues?.wersel_x, values?.wersel_x)
		const wersel_xl = !_.isEqual(initialValues?.wersel_xl, values?.wersel_xl)
		const wersel_platinum = !_.isEqual(initialValues?.wersel_platinum, values?.wersel_platinum)
		const timeline = !_.isEqual(initialValues?.timeline[0], values?.timeline[0])
		const valueChanged = labels || project_cost || wersel_x || wersel_xl || wersel_platinum || timeline
		return valueChanged
	}, [initialValues, values])

	const budgetRecommendAvl = useMemo(() => {
		const project_members = !_.isEqual(initialValues?.project_members, values?.project_members)
		return project_members
	}, [initialValues, values])

	// let arraykeyFind = values.infra_array.find((x) => x.infra_key === values.infrastructure_type)

	// let infraValues = {
	// 	infra_label: arraykeyFind.infra_select_label,
	// 	infra_unit: arraykeyFind.values.infra_unit,
	// 	infra_hrs: arraykeyFind.values.infra_hrs,
	// }

	// const { infra_label, infra_unit, infra_hrs } = infraValues

	// console.log([values.infrastructure_type].infra_select_label, values[values.infrastructure_type].infra_select_label, 'label')

	const removeQueryParams = () => {
		history.push({
			pathname: window.location.pathname,
			search: '',
		})
	}

	const closeAddPeoplexScreen = () => {
		setOpenPeoplex(false)
		setTimeout(() => {
			removeQueryParams()
		}, 800)
	}

	const openAddPeoplexScreen = () => {
		setOpenPeoplex(true)
	}

	const onPickedPeoplex = (data) => {
		const totalPeople = _.concat(projectMembers, data)
		const newPeople = _.uniqBy(totalPeople, 'id')
		closeAddPeoplexScreen()
		setProjectMembers(newPeople)
		handleChange({
			target: { name: 'project_members', value: newPeople },
		})
	}

	const handleRemovePeople = (id) => {
		let tempArray = [...projectMembers]
		_.remove(tempArray, { id: id })
		setProjectMembers(tempArray)
		handleChange({
			target: { name: 'project_members', value: tempArray },
		})
	}

	const handleDateChange = (dateObj) => {
		let dateArray = [
			{
				startDate: _.get(dateObj, 'dates.startDate', new Date()),
				endDate: _.get(dateObj, 'dates.endDate', new Date()),
				key: 'dates',
			},
		]
		setDates(dateArray)
		handleChange({
			target: { name: 'timeline', value: dateArray },
		})
	}

	// const handleInputChange = (event) => {
	// 	if (event.target.name === 'project_cost') {
	// 		setValue(event.target.value === '' ? '' : Number(event.target.value))
	// 		handleChange({
	// 			target: { name: 'project_cost', value: event.target.value === '' ? 0 : Number(event.target.value) },
	// 		})
	// 	}
	// 	if (event.target.name === 'infra_unit') {
	// 		setValue(event.target.value === '' ? '' : Number(event.target.value))
	// 		handleChange({
	// 			target: { name: `infra_unit`, value: event.target.value === '' ? 0 : Number(event.target.value) },
	// 		})
	// 	}
	// 	if (event.target.name === 'infra_hrs') {
	// 		setValue(event.target.value === '' ? '' : Number(event.target.value))
	// 		handleChange({
	// 			target: { name: `infra_hrs`, value: event.target.value === '' ? 0 : Number(event.target.value) },
	// 		})
	// 	}
	// }

	const handleSliderChange = (event, newValue) => {
		setValue(newValue)
		handleChange({
			target: { name: 'project_cost', value: newValue },
		})
	}

	const handleprogressBlur = () => {
		if (value < 0) {
			setValue(0)
		} else if (value > 100) {
			setValue(100)
		}
	}

	const handleRadioChange = (e) => {
		handleChange({
			target: { name: 'infrastructure_type', value: e.target.value },
		})
	}

	const isMobileView = useMediaQuery(theme.breakpoints.down(680))

	const projectPeopleSuggestion = (values, actions) => {
		const infra = []
		values.wersel_x.checked && infra.push(values.wersel_x)
		values.wersel_xl.checked && infra.push(values.wersel_xl)
		values.wersel_platinum.checked && infra.push(values.wersel_platinum)

		if (_.isEmpty(infra)) {
			dispatch(showSnackbarWithTimeout('Please Select Infrastructure', 1000))
		} else {
			dispatch(showLoader('Please wait...'))

			const data = {
				category: _.get(values, 'category', []).map((obj) => obj.label_id),
				labels: _.get(values, 'labels', []).map((obj) => obj.label_id),
				infrastructure_details: infra,
				project_start_date: moment(_.get(values, 'timeline[0].startDate', new Date())).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
				project_end_date: moment(_.get(values, 'timeline[0].endDate', new Date())).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
				project_cost: _.toString(_.get(values, 'project_cost', '')),
				created_by: _.get(values, 'created_by', null),
			}
			const onSuccess = (res) => {
				dispatch(hideLoader())
				const value = res.data.data
				setProjectMembers(value?.people)
				handleChange({
					target: { name: 'project_members', value: value?.people },
				})
				setInitVals({ ...values, project_members: value?.people })
			}
			const onFailure = (err) => {
				if (!Axios.isCancel(err)) dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			}
			if (cancelExecutor.current) {
				cancelExecutor.current()
			}
			ProjectCreationApi.projectsPeopleSuggestion(data, onSuccess, onFailure)
		}
	}

	const projectBudgetCalculation = (values, actions) => {
		const infra = []
		values.wersel_x.checked && infra.push(values.wersel_x)
		values.wersel_xl.checked && infra.push(values.wersel_xl)
		values.wersel_platinum.checked && infra.push(values.wersel_platinum)

		if (_.isEmpty(infra)) {
			dispatch(showSnackbarWithTimeout('Please Select Infrastructure', 1000))
		} else if (_.isEmpty(values?.project_members)) {
			dispatch(showSnackbarWithTimeout('Please Select People', 1000))
		} else {
			dispatch(showLoader('Please wait...'))

			const data = {
				project_members: _.get(values, 'project_members', []).map((obj) => obj.id),
				infrastructure_details: infra,
				project_start_date: moment(_.get(values, 'timeline[0].startDate', new Date())).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
				project_end_date: moment(_.get(values, 'timeline[0].endDate', new Date())).utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
				project_cost: _.toString(_.get(values, 'project_cost', '')),
			}
			const onSuccess = (res) => {
				dispatch(hideLoader())
				const value = res.data.data
				setInitVals({ ...values, project_members: values.project_members, project_cost: value?.revised_total_budget })
				dispatch(showSnackbarWithTimeout('Budget has been updated based on applied People changes', 1000))
				handleChange({
					target: { name: 'project_cost', value: value?.revised_total_budget },
				})
			}
			const onFailure = (err) => {
				if (!Axios.isCancel(err)) dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			}
			if (cancelExecutor.current) {
				cancelExecutor.current()
			}

			ProjectCreationApi.projectsBudgetCalculation(data, onSuccess, onFailure)
		}
	}

	return (
		<div className={classes.root}>
			{/* new code */}
			{isViewMode ? (
				<Grid container spacing={2} style={{ display: 'flex' }}>
					<Grid item xs={12} lg={6}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											Set Project Budget
										</Typography>
										<div style={{ padding: '10px 4px' }}>
											<div style={{ textAlign: 'center' }}>
												<Field
													type='number'
													name={`project_cost`}
													render={({ field, form }) => {
														return (
															<InputField
																type='number'
																// label='Describe your project below'
																field={field}
																form={form}
																// fullWidth
																// multiple
																// rows={4}
																disabled={isViewMode}
																InputProps={{ startAdornment: <Icon>attach_money</Icon> }}
																inputProps={{
																	step: 1,
																	min: 0,
																	max: 100000000,
																	type: 'number',
																	'aria-labelledby': 'input-slider',
																	style: { marginLeft: 5 },
																}}
																margin='dense'
															/>
														)
													}}
												/>
											</div>
											<div style={{ textAlign: 'center', padding: '20px 20px 0px 20px' }}>
												<PrettoSlider
													value={typeof value === 'number' ? value : 1}
													onChange={!isViewMode && handleSliderChange}
													valueLabelDisplay='auto'
													ValueLabelComponent={ValueLabelComponent}
													aria-label='pretto slider'
													defaultValue={20}
													marks={marks}
													step={1}
													min={0}
													max={100000000}
												/>
											</div>
										</div>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											Infrastructure
										</Typography>
										<div style={{ padding: '10px 4px' }}>
											<div style={{ margin: '10px' }}>
												<RadioGroup
													row
													aria-label='infra'
													name='infrastructure_type'
													value={values.infrastructure_type}
													onChange={handleRadioChange}
													defaultValue='top'
												>
													<FormControlLabel value='wersel_x' control={<Radio color='primary' />} label='Wersel X' labelPlacement='end' />
													<FormControlLabel value='wersel_xl' control={<Radio color='primary' />} label='Wersel XL' labelPlacement='end' />
													<FormControlLabel
														value='wersel_platinum'
														control={<Radio color='primary' />}
														label='Wersel Platinum'
														labelPlacement='end'
													/>
												</RadioGroup>
											</div>
											{isMobileView ? (
												<Grid container spacing={4} xs={12} lg={12} style={{ padding: 10 }}>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container item style={{ justifyContent: 'center' }}>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
																<img style={{ width: 60 }} alt={''} src={cpuImg} />
															</Grid>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
																<Typography variant='subtitle2'>
																	CPU -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 24 Core'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 48 Core'
																		: ' 192 Core'}
																</Typography>
															</Grid>
															<Grid item xs={12} lg={12} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 32
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 50
																			: 87
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container style={{ justifyContent: 'center' }}>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
																<img style={{ width: 60 }} alt={''} src={gpuImg} />
															</Grid>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
																<Typography variant='subtitle2'>
																	GPU -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' Nvidia RTX™ 6000 Ada'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' Nvidia 4xH100-80GB'
																		: ' Nvidia 8xH100-80GB'}
																</Typography>
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 20
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 35
																			: 82
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container style={{ justifyContent: 'center' }}>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
																<img style={{ width: 60 }} alt={''} src={memoryImg} />
															</Grid>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
																<Typography variant='subtitle2'>
																	RAM -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 128 GB'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 256 GB'
																		: ' 1 TB'}
																</Typography>
															</Grid>
															<Grid item xs={12} lg={12} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 45
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 52
																			: 93
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container style={{ justifyContent: 'center' }}>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
																<img style={{ width: 60 }} alt={''} src={memoryImg} />
															</Grid>
															<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
																<Typography variant='subtitle2'>
																	Storage -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 2 x 1.92 TB NVMe SSD'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 2 x 1.92 TB NVMe SSD'
																		: ' 8 x 1.92 TB NVMe SSD'}
																</Typography>
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 37
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 58
																			: 93
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											) : (
												<Grid container spacing={4} xs={12} lg={12} style={{ padding: 10 }}>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container item style={{ padding: '5px 0px' }}>
															<Grid item xs={12} lg={12}>
																<Typography variant='subtitle2'>
																	CPU -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 24 Core'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 48 Core'
																		: ' 192 Core'}
																</Typography>
															</Grid>
														</Grid>
														<Grid container item>
															<Grid item xs={4} lg={4}>
																<img style={{ width: 60 }} alt={''} src={cpuImg} />
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 32
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 50
																			: 87
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container item style={{ padding: '5px 0px' }}>
															<Grid item xs={12} lg={12}>
																<Typography variant='subtitle2'>
																	GPU -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' Nvidia RTX™ 6000 Ada'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' Nvidia 4xH100-80GB'
																		: ' Nvidia 8xH100-80GB'}
																</Typography>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={4} lg={4}>
																<img style={{ width: 60 }} alt={''} src={gpuImg} />
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 20
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 35
																			: 82
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container item style={{ padding: '5px 0px' }}>
															<Grid item xs={12} lg={12}>
																<Typography variant='subtitle2'>
																	RAM -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 128 GB'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 256 GB'
																		: ' 1 TB'}
																</Typography>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={4} lg={4}>
																<img style={{ width: 60 }} alt={''} src={memoryImg} />
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 45
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 52
																			: 93
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6} lg={6} className={classes.textField}>
														<Grid container item style={{ padding: '5px 0px' }}>
															<Grid item xs={12} lg={12}>
																<Typography variant='subtitle2'>
																	Storage -
																	{_.isEqual(values.infrastructure_type, 'wersel_x')
																		? ' 2 x 1.92 TB NVMe SSD'
																		: _.isEqual(values.infrastructure_type, 'wersel_xl')
																		? ' 2 x 1.92 TB NVMe SSD'
																		: ' 8 x 1.92 TB NVMe SSD'}
																</Typography>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={4} lg={4}>
																<img style={{ width: 60 }} alt={''} src={memoryImg} />
															</Grid>
															<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
																<Progressbar
																	level={
																		_.isEqual(values.infrastructure_type, 'wersel_x')
																			? 37
																			: _.isEqual(values.infrastructure_type, 'wersel_xl')
																			? 58
																			: 93
																	}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											)}
											<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
												<Field
													type='number'
													name={`${values.infrastructure_type}.core`}
													render={({ field, form }) => {
														return (
															<InputField
																type='number'
																// label='Describe your project below'
																field={field}
																form={form}
																// fullWidth
																// multiple
																// rows={4}
																disabled={isViewMode}
																InputProps={{ startAdornment: <Icon>memory</Icon> }}
																inputProps={{
																	// step: 10,
																	min: 1,
																	max: 100,
																	type: 'number',
																	'aria-labelledby': 'input-slider',
																	style: { marginLeft: 5 },
																}}
																margin='dense'
															/>
														)
													}}
												/>
												<Field
													type='number'
													name={`${values.infrastructure_type}.node`}
													render={({ field, form }) => {
														return (
															<InputField
																type='number'
																// label='Describe your project below'
																field={field}
																form={form}
																// fullWidth
																// multiple
																// rows={4}
																disabled={isViewMode}
																InputProps={{ startAdornment: <Icon>more_time</Icon> }}
																inputProps={{
																	// step: 10,
																	min: 1,
																	max: 100,
																	type: 'number',
																	'aria-labelledby': 'input-slider',
																	style: { marginLeft: 5 },
																}}
																margin='dense'
															/>
														)
													}}
												/>
											</div>
											<div style={{ marginTop: '10px', textAlign: 'center' }}>
												<FormControlLabel
													control={
														<Checkbox
															color='primary'
															name={`${values.infrastructure_type}.checked`}
															checked={values[values.infrastructure_type].checked}
														/>
													}
													label={values[values.infrastructure_type].infra_select_label}
													name={`${values.infrastructure_type}.checked`}
													onChange={handleChange}
													disabled={isViewMode}
												/>
											</div>
										</div>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid container spacing={2} style={{ height: '100%' }}>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											Timeline
										</Typography>
										<div className={classes.calenderTimeLine}>
											<TextField
												// style={{ width: 248 }}
												name='datePicker'
												id='outlined-required2'
												label='Start and End date'
												variant='outlined'
												// error={errors.datePicker && touched.datePicker && true}
												type='text'
												value={`${format(dates[0].startDate, 'dd/MM/yyyy')} - ${format(dates[0].endDate, 'dd/MM/yyyy')}`}
												// onChange={handleChange}
												onClick={() => !isViewMode && setIsCalendarOpen(!isCalendarOpen)}
												// onBlur={handleBlur}
												// helperText={errors.datePicker && touched.datePicker && errors.datePicker}
												InputProps={{
													endAdornment: (
														<IconButton
															aria-label='calender'
															onClick={() => !isViewMode && setIsCalendarOpen(!isCalendarOpen)}
															edge='end'
															size='small'
														>
															<DateRangeIcon />
														</IconButton>
													),
												}}
											/>
											{isCalendarOpen ? (
												<Dialog
													open={isCalendarOpen}
													maxWidth='md'
													disableRestoreFocus
													className={classes.calendarContainer}
													onClose={() => setIsCalendarOpen(false)}
												>
													{/* <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}> */}
													<DateRangePicker
														className={classes.calendarWrapper}
														onChange={(item) => {
															handleDateChange(item)
														}}
														showSelectionPreview={true}
														moveRangeOnFirstSelection={false}
														months={1}
														ranges={dates}
														staticRanges={fromTodayStaticRanges}
														inputRanges={fromTodayInputRange}
														minDate={new Date()}
														direction='horizontal'
													/>
													{/* </ClickAwayListener> */}
												</Dialog>
											) : null}
										</div>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											People
										</Typography>
										<Grid container spacing={1} className={classes.people}>
											{(isCreateMode || isEditMode) && (
												<Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
													<Button
														variant='text'
														size='small'
														onClick={() => {
															openAddPeoplexScreen()
														}}
													>
														<img src={AddButton} className={classes.addButton} alt='button' />
													</Button>
												</Grid>
											)}
											<Grid item xs={12} lg={12}>
												<PeopleCard
													projectMembers={projectMembers}
													handleRemovePeople={handleRemovePeople}
													isViewMode={isViewMode}
													isCreateMode={isCreateMode}
													isEditMode={isEditMode}
												/>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={2} style={{ display: 'flex' }}>
					<Grid item xs={12} lg={6}>
						<Grid container spacing={2} style={{ height: '100%' }}>
							<Grid item xs={12}>
								<Card style={{ height: '100%' }}>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											Set Project Budget
										</Typography>
										<div style={{ padding: '10px 4px' }}>
											<div style={{ textAlign: 'center' }}>
												<Field
													type='number'
													name={`project_cost`}
													render={({ field, form }) => {
														return (
															<InputField
																type='number'
																// label='Describe your project below'
																field={field}
																form={form}
																// fullWidth
																// multiple
																// rows={4}
																disabled={isViewMode}
																InputProps={{ startAdornment: <Icon>attach_money</Icon> }}
																inputProps={{
																	step: 1,
																	min: 0,
																	max: 100000000,
																	type: 'number',
																	'aria-labelledby': 'input-slider',
																	style: { marginLeft: 5 },
																}}
																margin='dense'
															/>
														)
													}}
												/>
											</div>
											<div style={{ textAlign: 'center', padding: '20px 20px 0px 20px' }}>
												<PrettoSlider
													value={typeof value === 'number' ? value : 1}
													onChange={!isViewMode && handleSliderChange}
													valueLabelDisplay='auto'
													ValueLabelComponent={ValueLabelComponent}
													aria-label='pretto slider'
													defaultValue={20}
													marks={marks}
													step={1}
													min={0}
													max={100000000}
												/>
											</div>
										</div>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<Card style={{ height: '100%' }}>
									<CardContent>
										<Typography className={classes.paperTitle} variant='body1'>
											Timeline
										</Typography>
										<div className={classes.calenderTimeLine}>
											<TextField
												// style={{ width: 248 }}
												name='datePicker'
												id='outlined-required2'
												label='Start and End date'
												variant='outlined'
												// error={errors.datePicker && touched.datePicker && true}
												type='text'
												value={`${format(dates[0].startDate, 'dd/MM/yyyy')} - ${format(dates[0].endDate, 'dd/MM/yyyy')}`}
												// onChange={handleChange}
												onClick={() => !isViewMode && setIsCalendarOpen(!isCalendarOpen)}
												// onBlur={handleBlur}
												// helperText={errors.datePicker && touched.datePicker && errors.datePicker}
												InputProps={{
													endAdornment: (
														<IconButton
															aria-label='calender'
															onClick={() => !isViewMode && setIsCalendarOpen(!isCalendarOpen)}
															edge='end'
															size='small'
														>
															<DateRangeIcon />
														</IconButton>
													),
												}}
											/>
											{isCalendarOpen ? (
												<Dialog
													open={isCalendarOpen}
													maxWidth='md'
													disableRestoreFocus
													className={classes.calendarContainer}
													onClose={() => setIsCalendarOpen(false)}
												>
													{/* <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}> */}
													<DateRangePicker
														className={classes.calendarWrapper}
														onChange={(item) => {
															handleDateChange(item)
														}}
														showSelectionPreview={true}
														moveRangeOnFirstSelection={false}
														months={1}
														ranges={dates}
														staticRanges={fromTodayStaticRanges}
														inputRanges={fromTodayInputRange}
														minDate={new Date()}
														direction='horizontal'
													/>
													{/* </ClickAwayListener> */}
												</Dialog>
											) : null}
										</div>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Card style={{ height: '97%' }}>
							<CardContent>
								<Typography className={classes.paperTitle} variant='body1'>
									Infrastructure
								</Typography>
								<div style={{ padding: '10px 4px' }}>
									<div style={{ margin: '10px' }}>
										<RadioGroup
											row
											aria-label='infra'
											name='infrastructure_type'
											value={values.infrastructure_type}
											onChange={handleRadioChange}
											defaultValue='top'
										>
											<FormControlLabel value='wersel_x' control={<Radio color='primary' />} label='Wersel X' labelPlacement='end' />
											<FormControlLabel value='wersel_xl' control={<Radio color='primary' />} label='Wersel XL' labelPlacement='end' />
											<FormControlLabel value='wersel_platinum' control={<Radio color='primary' />} label='Wersel Platinum' labelPlacement='end' />
										</RadioGroup>
									</div>
									{isMobileView ? (
										<Grid container spacing={4} xs={12} lg={12} style={{ padding: 10 }}>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container item style={{ justifyContent: 'center' }}>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
														<img style={{ width: 60 }} alt={''} src={cpuImg} />
													</Grid>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
														<Typography variant='subtitle2'>
															CPU -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 24 Core'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 48 Core'
																: ' 192 Core'}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={12} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 32 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 50 : 87
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container style={{ justifyContent: 'center' }}>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
														<img style={{ width: 60 }} alt={''} src={gpuImg} />
													</Grid>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
														<Typography variant='subtitle2'>
															GPU -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' Nvidia RTX™ 6000 Ada'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' Nvidia 4xH100-80GB'
																: ' Nvidia 8xH100-80GB'}
														</Typography>
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 20 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 35 : 82
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container style={{ justifyContent: 'center' }}>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
														<img style={{ width: 60 }} alt={''} src={memoryImg} />
													</Grid>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
														<Typography variant='subtitle2'>
															RAM -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 128 GB'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 256 GB'
																: ' 1 TB'}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={12} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 45 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 52 : 93
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container style={{ justifyContent: 'center' }}>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center' }}>
														<img style={{ width: 60 }} alt={''} src={memoryImg} />
													</Grid>
													<Grid item xs={12} lg={12} style={{ textAlignLast: 'center', padding: '4px 0px' }}>
														<Typography variant='subtitle2'>
															Storage -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 2 x 1.92 TB NVMe SSD'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 2 x 1.92 TB NVMe SSD'
																: ' 8 x 1.92 TB NVMe SSD'}
														</Typography>
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 37 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 58 : 93
															}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									) : (
										<Grid container spacing={4} xs={12} lg={12} style={{ padding: 10 }}>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container item style={{ padding: '5px 0px' }}>
													<Grid item xs={12} lg={12}>
														<Typography variant='subtitle2'>
															CPU -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 24 Core'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 48 Core'
																: ' 192 Core'}
														</Typography>
													</Grid>
												</Grid>
												<Grid container item>
													<Grid item xs={4} lg={4}>
														<img style={{ width: 60 }} alt={''} src={cpuImg} />
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 32 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 50 : 87
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container item style={{ padding: '5px 0px' }}>
													<Grid item xs={12} lg={12}>
														<Typography variant='subtitle2'>
															GPU -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' Nvidia RTX™ 6000 Ada'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' Nvidia 4xH100-80GB'
																: ' Nvidia 8xH100-80GB'}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid item xs={4} lg={4}>
														<img style={{ width: 60 }} alt={''} src={gpuImg} />
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 20 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 35 : 82
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container item style={{ padding: '5px 0px' }}>
													<Grid item xs={12} lg={12}>
														<Typography variant='subtitle2'>
															RAM -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 128 GB'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 256 GB'
																: ' 1 TB'}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid item xs={4} lg={4}>
														<img style={{ width: 60 }} alt={''} src={memoryImg} />
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 45 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 52 : 93
															}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={6} lg={6} className={classes.textField}>
												<Grid container item style={{ padding: '5px 0px' }}>
													<Grid item xs={12} lg={12}>
														<Typography variant='subtitle2'>
															Storage -
															{_.isEqual(values.infrastructure_type, 'wersel_x')
																? ' 2 x 1.92 TB NVMe SSD'
																: _.isEqual(values.infrastructure_type, 'wersel_xl')
																? ' 2 x 1.92 TB NVMe SSD'
																: ' 8 x 1.92 TB NVMe SSD'}
														</Typography>
													</Grid>
												</Grid>
												<Grid container>
													<Grid item xs={4} lg={4}>
														<img style={{ width: 60 }} alt={''} src={memoryImg} />
													</Grid>
													<Grid item xs={8} lg={8} style={{ alignSelf: 'center' }}>
														<Progressbar
															level={
																_.isEqual(values.infrastructure_type, 'wersel_x') ? 37 : _.isEqual(values.infrastructure_type, 'wersel_xl') ? 58 : 93
															}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									)}
									<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
										<Field
											type='number'
											name={`${values.infrastructure_type}.core`}
											render={({ field, form }) => {
												return (
													<InputField
														type='number'
														// label='Describe your project below'
														field={field}
														form={form}
														// fullWidth
														// multiple
														// rows={4}
														disabled={isViewMode}
														InputProps={{ startAdornment: <Icon>memory</Icon> }}
														inputProps={{
															// step: 10,
															min: 1,
															max: 100,
															type: 'number',
															'aria-labelledby': 'input-slider',
															style: { marginLeft: 5 },
														}}
														margin='dense'
													/>
												)
											}}
										/>
										<Field
											type='number'
											name={`${values.infrastructure_type}.node`}
											render={({ field, form }) => {
												return (
													<InputField
														type='number'
														// label='Describe your project below'
														field={field}
														form={form}
														// fullWidth
														// multiple
														// rows={4}
														disabled={isViewMode}
														InputProps={{ startAdornment: <Icon>more_time</Icon> }}
														inputProps={{
															// step: 10,
															min: 1,
															max: 50000,
															type: 'number',
															'aria-labelledby': 'input-slider',
															style: { marginLeft: 5 },
														}}
														margin='dense'
													/>
												)
											}}
										/>
									</div>
									<div style={{ marginTop: '10px', textAlign: 'center' }}>
										<FormControlLabel
											control={
												<Checkbox
													color='primary'
													name={`${values.infrastructure_type}.checked`}
													checked={values[values.infrastructure_type].checked}
												/>
											}
											label={values[values.infrastructure_type].infra_select_label}
											name={`${values.infrastructure_type}.checked`}
											onChange={handleChange}
											disabled={isViewMode}
										/>
									</div>
									{/* <div style={{ marginTop: '10px', textAlign: 'center' }}>
										<FormControlLabel
											control={
												<Checkbox
													color='primary'
													name={`${values.infrastructure_type}.checked`}
													checked={values[values.infrastructure_type]?.checked || false}
												/>
											}
											label={values[values.infrastructure_type]?.infra_select_label || ''}
											name={`${values.infrastructure_type}.checked`}
											onChange={handleChange}
											disabled={isViewMode}
										/>
									</div> */}
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} lg={12} style={{ marginBottom: '5px', textAlign: 'center' }}>
						<Button
							variant='contained'
							color='primary'
							disabled={!peopleRecommendAvl}
							className={classes.button}
							onClick={() => projectPeopleSuggestion(values)}
						>
							{'Recommend'}
						</Button>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Card className={classes.peopleEditMode}>
							<CardContent>
								<Typography className={classes.paperTitle} variant='body1'>
									People
								</Typography>
								<Grid container spacing={1} className={classes.people}>
									{(isCreateMode || isEditMode) && (
										<Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
											<Button
												variant='text'
												size='small'
												onClick={() => {
													openAddPeoplexScreen()
												}}
											>
												<img src={AddButton} className={classes.addButton} alt='button' />
											</Button>
										</Grid>
									)}
									<Grid item xs={12} lg={12} className={_.isEmpty(projectMembers) && classes.peopleList}>
										<PeopleCard
											projectMembers={projectMembers}
											handleRemovePeople={handleRemovePeople}
											isViewMode={isViewMode}
											isCreateMode={isCreateMode}
											isEditMode={isEditMode}
										/>
									</Grid>
									<Grid item xs={12} lg={12} style={{ marginBottom: '12px', marginBottom: '5px', textAlign: 'center' }}>
										<Button
											variant='contained'
											color='primary'
											className={classes.button}
											disabled={!budgetRecommendAvl}
											onClick={() => projectBudgetCalculation(values)}
										>
											{'Apply'}
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}

			{/* old code */}
			{/* <Grid spacing={2} container xs={12} lg={12}>
				<Grid item xs={12} lg={6}>
					<Paper className={classes.paper}>
						<Grid container item xs={12} lg={12}>
							<Grid item xs={12} lg={12} className={classes.textField}>
								<Typography className={classes.paperTitle} variant='body1'>
									Set project budget
								</Typography>
							</Grid>
							<Grid container item xs={12} lg={12} style={{ textAlign: 'center', padding: 10 }}>
								<Grid item xs={12} lg={12} className={classes.textField}>
									<Field
										type='number'
										name={`project_cost`}
										render={({ field, form }) => {
											return (
												<InputField
													type='number'
													// label='Describe your project below'
													field={field}
													form={form}
													// fullWidth
													// multiple
													// rows={4}
													disabled={isViewMode}
													InputProps={{ startAdornment: <Icon>attach_money</Icon> }}
													inputProps={{
														step: 10,
														min: 0,
														max: 100,
														type: 'number',
														'aria-labelledby': 'input-slider',
														style: { marginLeft: 5 },
													}}
													margin='dense'
												/>
											)
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={3} className={classes.textField}></Grid>
								<Grid item xs={12} lg={6} className={classes.textField}>
									<PrettoSlider
										value={typeof value === 'number' ? value : 0}
										onChange={!isViewMode && handleSliderChange}
										valueLabelDisplay='auto'
										aria-label='pretto slider'
										defaultValue={20}
										marks={marks}
									/>
									<Grid item xs={12} lg={3} className={classes.textField}></Grid>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
					<Paper className={classes.paper}>
						<Grid container item xs={12} lg={12}>
							<Grid item xs={12} lg={12} className={classes.textField}>
								<Typography className={classes.paperTitle} variant='body1'>
									Timeline
								</Typography>
							</Grid>
							<Grid item xs={12} lg={12} className={classes.calender}>
								<TextField
									// style={{ width: 248 }}
									name='datePicker'
									id='outlined-required2'
									label='Start and End date'
									variant='outlined'
									// error={errors.datePicker && touched.datePicker && true}
									type='text'
									value={`${format(dates[0].startDate, 'dd/MM/yyyy')} - ${format(dates[0].endDate, 'dd/MM/yyyy')}`}
									// onChange={handleChange}
									onClick={() => isCreateMode && setIsCalendarOpen(!isCalendarOpen)}
									// onBlur={handleBlur}
									// helperText={errors.datePicker && touched.datePicker && errors.datePicker}
									InputProps={{
										endAdornment: (
											<IconButton aria-label='calender' onClick={() => isCreateMode && setIsCalendarOpen(!isCalendarOpen)} edge='end' size='small'>
												<DateRangeIcon />
											</IconButton>
										),
									}}
								/>
								{isCalendarOpen ? (
									<Dialog
										open={isCalendarOpen}
										maxWidth='md'
										disableRestoreFocus
										className={classes.calendarContainer}
										onClose={() => setIsCalendarOpen(false)}
									>
										<DateRangePicker
											className={classes.calendarWrapper}
											onChange={(item) => {
												handleDateChange(item)
											}}
											showSelectionPreview={true}
											moveRangeOnFirstSelection={false}
											months={1}
											ranges={dates}
											staticRanges={fromTodayStaticRanges}
											inputRanges={fromTodayInputRange}
											minDate={new Date()}
											direction='horizontal'
										/>
									</Dialog>
								) : null}
							</Grid>
						</Grid>
					</Paper>
					<Paper className={classes.paper}>
						<Grid container xs={12} lg={12}>
							<Grid item xs={12} lg={12} className={classes.textField}>
								<Typography className={classes.paperTitle} variant='body1'>
									Infrastructure
								</Typography>
							</Grid>
							<Grid item xs={12} lg={12} style={{ margin: 10 }}>
								<RadioGroup
									row
									aria-label='infra'
									name='infrastructure_type'
									value={values.infrastructure_type}
									onChange={handleRadioChange}
									defaultValue='top'
								>
									<FormControlLabel value='wersel_x' control={<Radio color='primary' />} label='Wersel X' labelPlacement='end' />
									<FormControlLabel value='wersel_xl' control={<Radio color='primary' />} label='Wersel XL' labelPlacement='end' />
									<FormControlLabel value='wersel_platinum' control={<Radio color='primary' />} label='Wersel Platinum' labelPlacement='end' />
								</RadioGroup>
							</Grid>
							<Grid container spacing={1} item xs={12} lg={12} style={{ padding: 10 }}>
								<Grid item xs={12} lg={2} className={classes.textField}>
									<img style={{ width: 60 }} alt={''} src='https://cdn-icons-png.flaticon.com/512/6848/6848832.png' />
								</Grid>
								<Grid item xs={12} lg={4} className={classes.textField}>
									<Typography variant='subtitle2'>CPU</Typography>
									<Progressbar />
								</Grid>
								<Grid item xs={12} lg={2} className={classes.textField}>
									<img style={{ width: 60 }} alt={''} src='https://cdn3.iconfinder.com/data/icons/electronics-59/64/gpu-512.png' />
								</Grid>

								<Grid item xs={12} lg={4} className={classes.textField}>
									<Typography variant='subtitle2'>GPU</Typography>
									<Progressbar />
								</Grid>
								<Grid item xs={12} lg={2} className={classes.textField}>
									<img style={{ width: 60 }} alt={''} src='https://cdn.iconscout.com/icon/premium/png-256-thumb/computer-ram-3477924-2914221.png' />
								</Grid>
								<Grid item xs={12} lg={4} className={classes.textField}>
									<Typography variant='subtitle2'>Memory</Typography>
									<Progressbar />
								</Grid>
								<Grid item xs={12} lg={2} className={classes.textField}>
									<img style={{ width: 60 }} alt={''} src='https://cdn.iconscout.com/icon/premium/png-256-thumb/computer-ram-3477924-2914221.png' />
								</Grid>
								<Grid item xs={12} lg={4} className={classes.textField}>
									<Typography variant='subtitle2'>Storage</Typography>
									<Progressbar />
								</Grid>
							</Grid>
							<Grid item xs={12} lg={6} className={classes.textField} style={{ textAlign: 'center' }}>
								<Field
									type='number'
									name={`${values.infrastructure_type}.infra_unit`}
									render={({ field, form }) => {
										return (
											<InputField
												type='number'
												// label='Describe your project below'
												field={field}
												form={form}
												// fullWidth
												// multiple
												// rows={4}
												disabled={isViewMode}
												InputProps={{ startAdornment: <Icon>memory</Icon> }}
												inputProps={{
													step: 10,
													min: 0,
													max: 100,
													type: 'number',
													'aria-labelledby': 'input-slider',
													style: { marginLeft: 5 },
												}}
												margin='dense'
											/>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={6} className={classes.textField} style={{ textAlign: 'center' }}>
								<Field
									type='number'
									name={`${values.infrastructure_type}.infra_hrs`}
									render={({ field, form }) => {
										return (
											<InputField
												type='number'
												// label='Describe your project below'
												field={field}
												form={form}
												// fullWidth
												// multiple
												// rows={4}
												disabled={isViewMode}
												InputProps={{ startAdornment: <Icon>more_time</Icon> }}
												inputProps={{
													step: 10,
													min: 0,
													max: 100,
													type: 'number',
													'aria-labelledby': 'input-slider',
													style: { marginLeft: 5 },
												}}
												margin='dense'
											/>
										)
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={classes.textField} style={{ textAlign: 'center', marginTop: 10 }}>
								<FormControlLabel
									control={
										<Checkbox color='primary' name={`${values.infrastructure_type}.checked`} checked={values[values.infrastructure_type].checked} />
									}
									label={values[values.infrastructure_type].infra_select_label}
									name={`${values.infrastructure_type}.checked`}
									onChange={handleChange}
									disabled={isViewMode}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Paper className={classes.paper}>
						{isCreateMode &&
						<Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
							<Button variant='text' size='small' onClick={() => {openAddPeoplexScreen()}}>
								<img src={AddButton} className={classes.addButton} alt='button' />
							</Button>
						</Grid>}
						<Grid item xs={12} lg={12} style={{ padding: '12px 4px 4px 4px' }}>
							<PeopleCard projectMembers={projectMembers} handleRemovePeople={handleRemovePeople} isViewMode={isViewMode} isCreateMode={isCreateMode} />
						</Grid>
					</Paper>
				</Grid>
			</Grid> */}
			<AddPeoplexDialog
				dialogTitle='Choose People'
				open={openPeoplex}
				onClose={closeAddPeoplexScreen}
				onPickedPeoplex={onPickedPeoplex}
				projectMembers={values.project_members}
			></AddPeoplexDialog>
		</div>
	)
}
