import {
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Typography,
	Grid,
	makeStyles,
	IconButton,
	Icon,
	MenuItem,
	Avatar,
	Popover,
} from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import StickyAutoComplete from './StickyAutocomplete'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import EstimationInputBox from 'components/EstimationInputBox'
import RichTextEditor from 'components/RichTextEditor'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import CustomTooltip from 'components/ToolTip'
import { AvatarGroup } from '@material-ui/lab'
import { getErrMsg, getImgUrl, stringToHslColor, formTheName } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import GlassApi from 'services/glass/api'

const useStyles = makeStyles((theme) => ({
	detailsRoot: {
		padding: theme.spacing(2, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
	detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(0.5, 0),
		},
	},
	pr2: {
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			width: '100%',
		},
	},
	checkboxSec: {
		display: 'block',
		marginLeft: theme.spacing(0.5),
		// margin: theme.spacing(1, 0),
		'& .MuiTypography-body1': {
			color: theme.palette.almostBlack[600],
			fontSize: 12,
		},
		'& .PrivateSwitchBase-root-95': {
			padding: theme.spacing(0, 1, 0, 1.4),
		},
	},
	approveText: {
		color: theme.palette.success.dark,
	},
	bold: {
		fontWeight: '500',
	},
	noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
	priorityItem: {
		cursor: 'pointer',
	},
	objBg: {
		backgroundColor: '#f2f6fa',
		padding: theme.spacing(2, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
	richTextContent: {
		fontSize: 14,
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: '17px !important',
		cursor: 'pointer',
	},
	avatarGrid: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		gap: '5px',
		flexWrap: 'wrap',
		marginTop: theme.spacing(1),
		'& .MuiAvatar-root': {
			width: 32,
			height: 32,
			fontSize: '14px',
		},
	},
	untag: {
		padding: '2px 5px 2px 5px',
		minHeight: '0px',
		'& .MuiMenuItem-root': {},
	},
}))

const StickyDetailView = ({
	glassMembers,
	formik,
	disabled,
	estimationEnabled,
	membersSuggestions,
	glassCode,
	stickyCode,
	sessionId,
	stickyDetails,
	onClose,
	onUntagUser,
	panes,
}) => {
	const classes = useStyles()
	const [dueDateError, setDueDateError] = React.useState(false)

	const [isObjResultsOpen, setObjResultsOpen] = React.useState(false)

	const [anchorEl, setAnchorEl] = useState(null)
	const [untagUserDetail, setUntagUserDetail] = useState([])

	const dispatch = useDispatch()

	const toggleObjKeyResults = () => {
		setObjResultsOpen(!isObjResultsOpen)
	}

	const handleOpenPopper = (e, value) => {
		setAnchorEl(anchorEl ? null : e.currentTarget)
		setUntagUserDetail(value)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const token = useSelector((state) => _.get(state, 'session.authToken'))

	const getPicUrl = (profilePic) => getImgUrl(token, profilePic, 50)

	const untagUser = () => {
		setAnchorEl(null)
		const onSuccess = (resp) => {
			dispatch(hideLoader())
			setUntagUserDetail([])
			formik.setFieldValue('tagged_users', resp?.data?.data?.tagged_users)
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		askUntagUserConfirm(
			() => {
				dispatch(showLoader('Untagging User...'))
				const data = { user_id: untagUserDetail.user_id }
				GlassApi.untagUser(data, glassCode, stickyCode, sessionId).then(onSuccess, onError)
			},
			() => {
				dispatch(hideLoader())
			}
		)
	}

	const askUntagUserConfirm = (onConfirm, onCancel) => {
		let askMesg = `Are you sure you want to Untag the User?
		\n${untagUserDetail.email}`
		dispatch(showConfirmMessage(askMesg, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}

	const isPastDate = (selectedDate) => {
		const today = new Date()
		if (selectedDate < today) {
			return true
		}
		return false
	}
	const onDueDateChange = (selectedDate) => {
		let past = isPastDate(selectedDate)
		if (selectedDate === null) {
			formik.setFieldValue('due_date', null)
			setDueDateError(false)
		} else if (past) {
			setDueDateError(true)
		} else {
			formik.setFieldValue('due_date', selectedDate)
			setDueDateError(false)
		}
	}
	const isCompleteStatus = useMemo(() => {
		return formik.values?.status === 'completed'
	}, [formik.values.status])
	const isApproveStatus = useMemo(() => {
		return formik.values?.status === 'approved'
	}, [formik.values.status])

	const isRejectStatus = useMemo(() => {
		return formik.values?.status === 'rejected'
	}, [formik.values.status])

	return (
		<form onSubmit={formik?.handleSubmit}>
			<Popover
				open={open}
				id={id}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem className={classes.untag} onClick={untagUser}>
					Untag
				</MenuItem>
			</Popover>
			<Grid item lg={12} md={12} className={classnames(classes.detailsContent, classes.objBg)}>
				<Typography className={classes.bold} variant='body2' gutterBottom>
					Objectives and Key Results
				</Typography>
				{isObjResultsOpen ? (
					<>
						<RichTextEditor
							name={'sticky_desc'}
							placeholder=''
							value={formik?.values?.sticky_desc ? formik?.values?.sticky_desc : ''}
							// onChange={formik?.handleChange}
							onBlur={(e, value) => {
								formik?.handleChange(e)
								toggleObjKeyResults()
							}}
							readOnly={disabled}
							initialFocus={true}
							hideToolbarOnFocus={false}
							suggestions={membersSuggestions}
							delayedUpdate={300}
						/>
					</>
				) : (
					<>
						<div role='button' onClick={disabled ? null : toggleObjKeyResults}>
							{formik?.values?.sticky_desc &&
							!_.isEmpty(ContentState.createFromBlockArray(htmlToDraft(formik?.values?.sticky_desc)).getPlainText()) ? (
								<div className={classes.richTextContent} dangerouslySetInnerHTML={{ __html: formik?.values?.sticky_desc }}></div>
							) : (
								<p className={classes.noneSection}>None</p>
							)}
						</div>
					</>
				)}
			</Grid>
			<Grid container className={classes.detailsRoot}>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<StickyAutoComplete
						glassMembers={glassMembers}
						title='Pane'
						name='pane'
						disabled={disabled}
						isPane={true}
						panes={panes}
						value={formik?.values?.pane}
						onChange={(e, v) => {
							formik.setFieldValue('pane', v?.value)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<StickyAutoComplete
						glassMembers={glassMembers}
						title='Ratings'
						name='ratings'
						disabled={true}
						isRating={true}
						value={formik?.values?.ratings}
						// onChange={(e, v) => {
						// 	formik.setFieldValue('pane', v?.value)
						// }}
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<StickyAutoComplete
						options={glassMembers}
						title='Assignee'
						name='assigned_to'
						disabled={disabled}
						value={formik?.values?.assigned_to}
						onChange={(e, v) => {
							formik.setFieldValue('assigned_to', v)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classes.detailsContent}>
					<StickyAutoComplete
						options={glassMembers}
						title='Reporting To'
						name='reporting_to'
						disabled={disabled}
						error={formik?.errors?.reporting_to}
						value={formik?.values?.reporting_to}
						onChange={(e, v) => {
							formik.setFieldValue('reporting_to', v)
						}}
					/>
					{formik?.errors?.reporting_to && <FormHelperText error>{formik?.errors?.reporting_to}</FormHelperText>}
					<div className={classes.checkboxSec}>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(formik?.values?.is_approval)}
									disabled={disabled || isCompleteStatus || isApproveStatus || isRejectStatus}
									name='is_approval'
									onChange={(e) => {
										formik.setFieldValue('is_approval', !formik?.values?.is_approval)
									}}
									color='primary'
									size='small'
								/>
							}
							label='Does this task need an approval?'
						/>
					</div>
					{isRejectStatus || isApproveStatus ? (
						<FormHelperText className={classes.approveText} error={isRejectStatus}>
							{_.capitalize(formik?.values?.status)}
						</FormHelperText>
					) : null}
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<StickyAutoComplete
						glassMembers={glassMembers}
						title='Priority'
						name='priority'
						disabled={disabled}
						isPriority={true}
						value={formik?.values?.priority}
						onChange={(e, v) => {
							formik.setFieldValue('priority', v?.value)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent)}>
					<StickyAutoComplete
						title='Due Date & Time'
						name='due_date'
						disabled={disabled}
						error={dueDateError}
						isDueDate={true}
						value={formik?.values?.due_date}
						onChange={(e, v) => {
							onDueDateChange(v)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<Typography variant='subtitle2' component='p' gutterBottom>
						Original Estimation{' '}
						<CustomTooltip title='This only considers working days' placement='right-start'>
							<IconButton onClick={null} className={classes.infoBtn} size='small'>
								<Icon fontSize='small'>info</Icon>
							</IconButton>
						</CustomTooltip>
					</Typography>
					<EstimationInputBox
						name='estimation_time'
						value={formik?.values?.estimation_time}
						onChange={formik?.handleChange}
						readOnly={!estimationEnabled}
						size='small'
						variant='outlined'
						placeholder='2w 4d 6h 45m'
					/>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent)}>
					<Typography variant='subtitle2' component='p' gutterBottom>
						Tagged User(s)
					</Typography>
					{!_.isEmpty(formik?.values?.tagged_users) ? (
						<>
							<Grid item xs={12} className={classes.avatarGrid}>
								<AvatarGroup max={8}>
									{_.map(formik?.values?.tagged_users, (tagged_user) => (
										<Avatar
											title={`${formTheName(tagged_user?.first_name || '', tagged_user?.last_name || '')}`}
											style={{ backgroundColor: stringToHslColor(`${tagged_user.first_name} ${tagged_user.last_name}`) }}
											size='small'
											className={classes.avatar}
											key={tagged_user.user_id}
											src={getPicUrl(tagged_user.profile_img) || null}
											onClick={(e) => handleOpenPopper(e, tagged_user)}
										>
											{_.slice(_.words(formTheName(tagged_user?.first_name, tagged_user?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
										</Avatar>
									))}
								</AvatarGroup>
							</Grid>
						</>
					) : (
						<p className={classes.noneSection} style={{ marginTop: '11px' }}>
							None
						</p>
					)}
				</Grid>
			</Grid>
		</form>
	)
}

StickyDetailView.propTypes = {
	glassMembers: PropTypes.array,
	formik: PropTypes.object,
	disabled: PropTypes.bool,
}

export default StickyDetailView
